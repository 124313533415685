<template>
	<div style="" class="quote">
		<br/>
		<br/>
		<br/>
		<div style="text-align: center;">
			<img class="img-fluid mt-2" :src="getLogo()" v-if="order.organization.logo" style="max-width: 300px;">
			<h1 v-else>{{order.organization.name}}</h1>
		</div>
		<h2 style="text-align: center;">Your moving is on tomorrow</h2>
		<p style="text-align: center;">Just a friendly reminder that</p>

		<div style="display: flex; justify-content: center;flex-direction: column;align-items: center;">
			
			<h3>Date/Time</h3>
			<p>{{order.planStartTime}}<template v-if="order.planEndTime"> --- {{order.planEndTime}} </template></p>
			<p>{{dayOfWeek(order.dayOfWeek)}}</p>
			<p v-if="order.planEndTime"> this is an estimated window of arrival</p>
			<div style="border-top: 1px solid rgba(0,0,0,.125);width: 100%;">&nbsp;</div>
			<h3>Address</h3>
			<!-- <ul>
				<li v-for="address in order.addressList" style="margin-top: 10px;">
					<span>{{$dv(loadTypeList,address.loadType)}}</span>
					<span>{{address.address}}</span>
					<span v-if="address.type">type:{{$dv(addressTypeList,address.type)}}</span>
					<span v-if="address.apt">apt/suite:{{address.apt}}</span>
					<span v-if="address.note">note:{{address.note}}</span>
				</li>
			</ul> -->
			<ul style="list-style-type: decimal;padding-left: 15px;">
				<li v-for="item,index in order.addressList">
					<div class="address-card" >
						<p>
							<table border="1" cellspacing="0" style="width: 100%;margin-top: 5px;margin-top: 20px">
								<tr><td>loadType</td><td>{{$dv(loadTypeList,item.loadType)}}</td></tr>
								<tr v-if="item.address"><td>address</td><td>{{item.address}}</td></tr>
								<tr><td>type</td><td>{{$dv(addressTypeList,item.type)}}</td></tr>
								<tr><td>apt/suite</td><td>{{item.apt}}</td></tr>
								<tr v-if="item.contact"><td>contact</td><td>{{item.contact}}</td></tr>
								<tr v-if="item.phone"><td>phone</td><td>{{item.phone}}</td></tr>
								<tr v-if="item.elevator"><td>elevator</td><td>{{item.elevator}}</td></tr>
								<tr v-if="item.longWalk"><td>longWalk</td><td>{{item.longWalk}}</td></tr>
								<tr v-if="item.slope"><td>slope</td><td>{{item.slope}}</td></tr>
								<tr v-if="item.parking"><td>parking</td><td>{{item.parking}}</td></tr>
								<tr v-if="item.accessCode"><td>gateAccessCode</td><td>{{item.accessCode}}</td></tr>
								<tr v-if="item.parkingRestrict"><td>parkingRestrict</td><td>{{item.parkingRestrict}}</td></tr>
								<tr v-if="item.timeRestrict"><td>timeRestrict</td><td>{{item.timeRestrict}}</td></tr>
								<tr v-if="item.notInMap"><td>notInMap</td><td>{{item.notInMap}}</td></tr>
								<tr v-if="item.rooms"><td>bedrooms</td><td>{{item.rooms}}</td></tr>
								<tr v-if="item.level"><td>level</td><td>{{item.level}}</td></tr>
								<tr v-if="item.floor"><td>floor</td><td>{{item.floor}}</td></tr>
								<tr v-if="item.stair"><td>stair</td><td>{{item.stair}}</td></tr>
								<tr v-if="item.moveSize"><td>moveSize(FT)</td><td>{{item.moveSize}}</td></tr>
								<tr v-if="item.moveSizeLBS"><td>moveSize(LBS)</td><td>{{item.moveSizeLBS}}</td></tr>
								<tr v-if="item.note"><td>note</td><td>{{item.note}}</td></tr>
							</table>
						</p>
					</div>
				</li>
			</ul>
			<div style="border-top: 1px solid rgba(0,0,0,.125);width: 100%;">&nbsp;</div>
			<h3>Contact</h3>
			<p>
				<span v-if="order.customer.firstname || order.customer.lastname">
					Fullname:{{(order.customer.firstname?? '')+' '+(order.customer.lastname ?? '')}},
				</span>
				<span v-if="order.customer.cnname">
					名字:{{order.customer.cnname}},
				</span>
				<span v-if="order.customer.email">
					Email:{{order.customer.email}},
				</span>
				<span v-if="order.customer.phone">
					Phone:{{order.customer.phone}},
				</span>
				<span v-if="order.customer.phone2">
					Phone2:{{order.customer.phone2}}
				</span>
			</p>
			<div style="border-top: 1px solid rgba(0,0,0,.125);width: 100%;">&nbsp;</div>
			<h3>Movers</h3>
			<p>{{mainService.name}}</p>
			<div style="border-top: 1px solid rgba(0,0,0,.125);width: 100%;">&nbsp;</div>
			<h3>Payment</h3>
			<p>{{getPayment(order.payment)}}</p>
			<div style="border-top: 1px solid rgba(0,0,0,.125);width: 100%;">&nbsp;</div>
			<h3>Price</h3>
			<p>
				<table border="1" cellspacing="0" style="width: 100%;table-layout: fixed;">
					<tr>
						<th>Name</th>
						<th>Price</th>
						<th>Quantity<br>/Hour</th>
						<th>Cost</th>
						<th>Note</th>
					</tr>
					<tr>
						<td>{{mainService.name}}</td>
						<td>{{mainService.price}}</td>
						<td><template v-if="!mainService.hideMinHour">{{mainService.minHour}}</template></td>
						<td>{{mainService.price * mainService.minHour}}</td>
						<td>{{mainService.note}}</td>
					</tr>
					<tr v-for="subService in order.subServiceList">
						<td>{{subService.name}}</td>
						<td>{{subService.price}}</td>
						<td>{{subService.quantity}}</td>
						<td>{{subService.price * subService.quantity}}</td>
						<td>{{subService.note}}</td>
					</tr>
					<tr v-for="material in order.materialList">
						<td>{{material.name}}</td>
						<td>{{material.price}}</td>
						<td>{{material.quantity}}</td>
						<td>{{material.price * material.quantity}}</td>
						<td>{{material.note}}</td>
					</tr>
					<tr v-if="order.returnTrip">
						<td>ReturnTrip</td>
						<td>{{order.returnTrip}}</td>
						<td></td>
						<td>{{order.returnTrip}}</td>
						<td></td>
					</tr>
					<tr v-if="order.fuel">
						<td>Fuel</td>
						<td>{{order.fuel}}</td>
						<td></td>
						<td>{{order.fuel}}</td>
						<td></td>
					</tr>
					<tr v-if="order.deposit">
						<td>Deposit</td>
						<td>-{{order.deposit}}</td>
						<td></td>
						<td>-{{order.deposit}}</td>
						<td></td>
					</tr>
					<tr v-if="order.discountAmount">
						<td>OtherCost</td>
						<td>{{order.discountAmount}}</td>
						<td></td>
						<td>{{order.discountAmount}}</td>
						<td></td>
					</tr>
					<tr>
						<td colspan="3">Minimum</td>
						<td>${{quoteTotal}}</td>
						<td><MinChargeTip></MinChargeTip></td>
					</tr>
				</table>
			</p>
			<div style="border-top: 1px solid rgba(0,0,0,.125);width: 100%;">&nbsp;</div>
			<h3>Note</h3>
			<p v-html="formatLineWrap(order.noteForCustomer)"></p>
			<div style="border-top: 1px solid rgba(0,0,0,.125);width: 100%;">&nbsp;</div>
			<Tip></Tip>
			<p>Please let us know by 5pm today if you need any boxes, wrapping paper, or any packing materials.</p>
			<!-- Mandarin/中文=3 -->
			<p v-if="order.customer.lang==3">
				感谢您的预约，请阅读以下policy，如有任何问题请随时提出来，如果需要小纸箱和打包纸，请至少提前一天通知我们，谢谢！ <a :href="'/#/p/'+order.organization.chinesePolicy">{{getPolicyLink(order.organization.chinesePolicy)}}</a> 
			</p>
			<p v-else>
				Prior to your move, please read our policy statement at:
				 <a :href="'/#/p/'+order.organization.englishPolicy">{{getPolicyLink(order.organization.englishPolicy)}}</a>
				Any questions please feel free to ask me:)   Please pre-order boxes and wrapping paper at least one day prior to your move if required, thank you!
			</p>
			<p>Any changes please contact us at {{order.organization?.phone}} {{order.organization?.phone2}}</p>
			<!-- <div style="border-top: 1px solid rgba(0,0,0,.125);width: 100%;">&nbsp;</div>
			<div style="color: #dc3545">Any changes please contact us at {{order.organization?.phone}} {{order.organization?.phone2}}</div> -->
			<hr>
			
		</div>
		<br/>
		<div style="text-align: center;">
			<button type="submit" class="btn-confirm" @click="confirm" v-if="order.status=='5'">RECONFIRM</button>
			<button type="submit" class="btn-disabled" v-else>RECONFIRM</button>
		</div>
		<br/>
		<br/>
		<br/>
		<Cancelled v-if="order.status=='2'"></Cancelled>
	</div>
</template>
<script>
	import axios from 'axios';
	import {getDict} from '../api/base.js';
	import {getOrder} from '../api/order.js';
	import {isBlank,dayOfWeek,formatLineWrap,getPolicyLink} from '../utils/utils.js';
	import Tip from './Tip.vue';
	import MinChargeTip from './MinChargeTip.vue';
	import Cancelled from './Cancelled.vue';
	

	const confirmOrder = (token) => axios.post("/open/order/notice/confirm/" + token);

	export default {
		data() {
			return {
				order: {
					customer: {},
					mainServiceId: undefined,
					mainServiceList: [],
					subServiceList: [],
					materialList: [],
					organization:{}

				},
				langTypeList: [],
				loadTypeList: [],
				addressTypeList: [],
				paymentList:[],
				token: undefined

			};
		},
		computed: {
			orgName() {
				if (this.order.organization) {
					var orgName = this.order.organization.name ?? "";
					orgName += this.order.organization.cnname ?? "";
					return orgName;
				} else {
					return null;
				}

			},
			quoteTotal() {
				var sum = 0;
				sum += this.mainService.price * this.mainService.minHour;
				var subServiceList = this.order.subServiceList;
				for (var item of subServiceList) {
					sum += item.price * item.quantity;
				}
				var materialList = this.order.materialList;
				for (var item of materialList) {
					sum += item.price * item.quantity;
				}
				sum += (this.order.returnTrip ?? 0) + (this.order.fuel ?? 0) ;
				sum -= (this.order.deposit ?? 0);
				sum += (this.order.discountAmount ?? 0);
				return sum;
			},
			mainService() {
				return this.order.mainServiceList.find(item => item.id == this.order.mainServiceId) ?? {};
			}
		},
		components:{
			Tip,
			MinChargeTip,
			Cancelled
		},
		methods: {
			dayOfWeek,
			formatLineWrap,
			getPolicyLink,
			confirm() {

				this.$alert('Confirm no problem?', '', {
					confirmButtonText: '确定 Ok',
					// cancelButtonText: '取消 cancel',
					type: 'info'
				}).then(() => {
					confirmOrder(this.token)
						.then(() => {
							this.$router.push("/thanks")
						});
				});
			},
			getLogo(){
				return axios.defaults.baseURL + "/statics" + this.order.organization.logo;
			},
			getPayment(payment){
				if(payment){
					var arr=[];
					for (let s of payment.ids) {
						arr.push(this.$dv(this.paymentList,s));
					}
					return arr.join();
				}else{
					return null;
				}
			}


		},
		created() {
			this.token = this.$route.params.id;
			if (isBlank(this.token)) {
				this.$message.warning("token not be empty!");
				return;
			}
			getDict("langType", this.langTypeList, (a, b) => a.id - b.id); //渲染状态<option>标签,此处下拉框中的值不能写死，需要从后台获取
			getDict("loadType", this.loadTypeList, (a, b) => a.id - b.id);
			getDict("address", this.addressTypeList, (a, b) => a.id - b.id);
			getDict("payment", this.paymentList,(a,b)=>a.id-b.id); 
			
			getOrder(this.token).then(res => {
				this.order = res.data;
			});
		},
		mounted() {
			document.querySelector("head > title").innerText="Tomorrow";
		}
	}
</script>
<style>
	@import url("../assets/css/datatable.css");

	.el-row {
		border-bottom: 1px solid black;
	}

	.el-col:first-child {
		text-align: right;
		background-color: #eee;
		font-weight: bold;
	}

	
	.quote span {
		background-color: #eee;
		margin-left: 10px;
	}
	.card-header {
		/* padding: 0.75rem 1.25rem;
	    margin-bottom: 0;*/
		/* background-color: rgba(0,0,0,.03); */
		border-bottom: 1px solid rgba(0, 0, 0, .125);
	}

	.quote {
		padding: 10px;
	}

	.callout {
		border-radius: 0.25rem;
		box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);
		background-color: #fff;
		border-left: 5px solid #e9ecef;
		margin-bottom: 1rem;
		padding: 1rem;
	}

	.callout.callout-danger {
		border-left-color: #bd2130;
	}

	.callout.callout-info {
		border-left-color: #117a8b;
	}

	.callout.callout-warning {
		border-left-color: #d39e00;
	}

	.callout.callout-success {
		border-left-color: #1e7e34;
	}

	table th,
	table td{
		word-wrap: break-word;
	}

	.card p {
		/* font-size: 8px; */
		word-wrap: break；
	}

	.btn-block {
		display: block;
		width: 100%;
	}

	.btn-group-lg>.btn,
	.btn-lg {
		padding: 0.5rem 1rem;
		font-size: 1.25rem;
		line-height: 1.5;
		border-radius: 0.3rem;
	}

	.btn-success {
		color: #fff;
		background-color: #28a745;
		border-color: #28a745;
	}

	.btn {
		/* display: inline-block; */
		font-weight: 400;
		color: #212529;
		text-align: center;
		/*  vertical-align: middle;
	    -webkit-user-select: none;
	    -moz-user-select: none;
	    -ms-user-select: none;
	    user-select: none;
	    background-color: transparent; */
		border: 1px solid transparent;
		padding: 0.375rem 0.75rem;
		font-size: 1rem;
		line-height: 1.5;
		border-radius: 0.25rem;
		transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
	}
	
	
	
	.btn-confirm {
		display: block;
		width: 100%;
	
		padding: 0.5rem 1rem;
		font-size: 1.25rem;
		line-height: 1.5;
		border-radius: 0.3rem;
	
		color: #fff;
		background-color: #28a745;
		border-color: #28a745;
	
		/* display: inline-block; */
		font-weight: 400;
		color: #212529;
		text-align: center;
		/*  vertical-align: middle;
	    -webkit-user-select: none;
	    -moz-user-select: none;
	    -ms-user-select: none;
	    user-select: none;
	    background-color: transparent; */
		border: 1px solid transparent;
		padding: 0.375rem 0.75rem;
		font-size: 1rem;
		line-height: 1.5;
		border-radius: 0.25rem;
		transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
		
	}
	.btn-confirm:hover{
		cursor: pointer;
	}
	.btn-disabled {
		display: block;
		width: 100%;
	
		padding: 0.5rem 1rem;
		font-size: 1.25rem;
		line-height: 1.5;
		border-radius: 0.3rem;
	
		color: #fff;
		background-color: darkgray;
	
		/* display: inline-block; */
		font-weight: 400;
		color: #212529;
		text-align: center;
		/*  vertical-align: middle;
	    -webkit-user-select: none;
	    -moz-user-select: none;
	    -ms-user-select: none;
	    user-select: none;
	    background-color: transparent; */
		border: 1px solid transparent;
		padding: 0.375rem 0.75rem;
		font-size: 1rem;
		line-height: 1.5;
		border-radius: 0.25rem;
		transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
	}
</style>